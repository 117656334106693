import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const WebsiteEditor = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadSuccess(false);
    setUploadError(false);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/uploadMaterialTrendsImage", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setUploadSuccess(true);
        setFile(null); // Clear the file input after a successful upload
      } else {
        setUploadError(true);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadError(true);
    }
  };

  const handleBlogSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("thumbnail", image);
    formData.append("summary", summary);
    formData.append("category", category);

    try {
      const response = await fetch("/createBlog", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Blog created successfully!");
        setTitle("");
        setContent("");
        setSummary("");
        setImage(null);
        setCategory("");
      } else {
        alert("Failed to create blog");
      }
    } catch (error) {
      console.error("Error creating blog:", error);
      alert("An error occurred while creating the blog");
    }
  };

  const toolbarOptions = [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["blockquote", "code-block"],
    ["clean"],
  ];

  return (
    <div className="p-4">
      {/* Upload Material Trends Image Section */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Upload Material Trends Image</h2>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="mb-4"
        />
        <button
          onClick={handleFileUpload}
          className="m-2 p-2 bg-blue-400 text-white rounded-sm"
          disabled={!file} // Disable the button if no file is selected
        >
          Upload Image
        </button>
        {uploadSuccess && (
          <p className="text-green-500">Image uploaded successfully!</p>
        )}
        {uploadError && (
          <p className="text-red-500">
            Error uploading image. Please try again.
          </p>
        )}
      </div>

      {/* Create a Blog Post Section */}
      <div>
        <h2 className="text-xl font-bold mb-4">Create a Blog Post</h2>
        <form onSubmit={handleBlogSubmit}>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Blog Title"
            required
            className="p-2 border mb-2 w-full"
          />
          <input
            type="text"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            placeholder="Enter a Short Summary of Blog"
            required
            className="p-2 border mb-2 w-full"
          />

          <label htmlFor="category">Category</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            className="p-2 border mb-2 w-full"
          >
            <option value="" disabled>
              Select a category
            </option>
            <option value="KitchensBathrooms">Kitchens & Bathrooms</option>
            <option value="Cabinets">Cabinets</option>
            <option value="Roofing">Roofing</option>
            <option value="HeatingCooling">Heating & Cooling</option>
            <option value="WindowsDoors">Windows & Doors</option>
            <option value="Insulation">Insulation</option>
            <option value="Decking">Decking</option>
            <option value="Siding">Siding</option>
          </select>

          <p>Blog Content</p>
          <ReactQuill
            value={content}
            onChange={setContent}
            placeholder="Write your blog content here..."
            className="mb-2"
            modules={{ toolbar: toolbarOptions }}
          />

          <p>Blog Thumbnail</p>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
            required
            className="m-2"
          />

          <button
            className="m-2 p-2 bg-blue-400 text-white rounded-sm"
            type="submit"
          >
            Create Blog
          </button>
        </form>
      </div>
    </div>
  );
};

export default WebsiteEditor;
